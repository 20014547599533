/***************
   VARIABLES
***************/

//SPACERS
$fc2-max-width:             380px;
$fc2-spacer-10:             10px;
$fc2-spacer-15:             15px;
$fc2-spacer-20:             20px;
$fc2-spacer-25:             25px;
$fc2-spacer-30:             30px; 

//COLORS
$fc2-white:                 #fff;
$fc2-gray-100:              #E7E7E7;
$fc2-gray-200:              #8A8A8A;
$fc2-gray-300:              #9B9B9B;
$fc2-success:               #B8E986;
$fc2-refused:               #BB2222;
$fc2-alpha:                 $fc2-gray-200;

$fc2-border-color:          $fc2-alpha;
$fc2-link-color:            $fc2-alpha;
$fc2-link-color-hover:      darken($fc2-alpha, 40%);

$fc2-border:                1px solid $fc2-border-color;
$fc2-border-light:          1px solid $fc2-gray-100;

//FONT
$fc2-font:                  Helvetica, sans-serif;
$fc2-fs-15:                 15px;
$fc2-fs-12:                 12px;
$fc2-fw-light:              300;
$fc2-fw-medium:             500;

//SWITCH
$fc2-switch-width:          40px;
$fc2-switch-height:         20px;

$fc2-switch-round-width:    16px;
$fc2-switch-round-distance: 2px; 


/***************
   LAYOUT
***************/

.fc2-wrapper {
  z-index: 10;
  position: fixed;
  display: none;
  bottom: $fc2-spacer-20;
  right: $fc2-spacer-20;
  max-width: $fc2-max-width;
  max-height: calc(100vh - #{$fc2-spacer-20} * 2);
  background: $fc2-white;
  border: $fc2-border;
  overflow: auto;

  &.active {
    display: block;
  }

  //RESET
  a,
  button,
  input,
  p {
    margin: 0;
    font-family: $fc2-font;
    font-size: $fc2-fs-15;
    font-weight: $fc2-fw-light;
    color: $fc2-alpha;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .fc2-title {
    font-weight: $fc2-fw-medium;
  }

  .fc2-link {
    display: inline-block;
    margin-top: $fc2-spacer-25;
    transition: .2s;

    &:hover {
      color: $fc2-link-color-hover;
    }
  }

  .fc2-button {
    padding: $fc2-spacer-10;
    border: none;
    box-shadow: none;
    cursor: pointer;
    transition: .2s;

    &:hover {
      color: $fc2-link-color-hover;
    }
  }

}

.fc2-header {
  padding: $fc2-spacer-15 $fc2-spacer-25;
  border-bottom: $fc2-border;
}

.fc2-body {
  padding: $fc2-spacer-25;
}

.fc2-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: $fc2-border;

  .fc2-button {
    flex: 1;
    &:focus {
      outline: none;
    }
    +.fc2-button {
      border-left: $fc2-border;
    }
  }

}

/***************
   COMPONENTS
***************/

//SWITCH

.fc2-switch {
  position: relative;

  .fc2-switch-label {
    position: relative;
    width: $fc2-switch-width;
    min-width: $fc2-switch-width;
    height: $fc2-switch-height;
    background: $fc2-refused;
    border-radius: calc(#{$fc2-switch-height}/2);
    transition: background .3s;

    &:before {
      content: "";
      position: absolute;
      left: $fc2-switch-round-distance;
      top: $fc2-switch-round-distance;
      display: block;
      width: $fc2-switch-round-width;
      height: $fc2-switch-round-width;
      background: $fc2-white;
      border-radius: calc(#{$fc2-switch-round-width}/2);
      transition: left .3s;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }

  input:checked {
    +.fc2-switch-label {
      background: $fc2-success;
      &:before {
        left: calc(100% - #{$fc2-switch-round-distance} - #{$fc2-switch-round-width});
      }
    }
  }
}

//LIST

.fc2-list-item {

  +.fc2-list-item {
    padding-top: $fc2-spacer-30;
  }
}

.fc2-list-header {
  padding-bottom: $fc2-spacer-10;
  border-bottom: $fc2-border;
}

//COLLAPSE

.fc2-collapse {

  .fc2-title {
    font-weight: $fc2-fw-light;
    cursor: pointer;
  }
}

.fc2-collapse-item {
  padding: $fc2-spacer-10 0;
  border-bottom: $fc2-border-light;

  &.active {

    .fc2-collapse-icon {
      border-width: 0 5px 7px 5px;
      border-color: transparent transparent $fc2-gray-100 transparent;
    }

    .fc2-collapse-body {
      max-height: 100%;
      padding-top: $fc2-spacer-10;
      opacity: 1;
    }
  }
}

.fc2-collapse-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fc2-title {
    flex: 1;
  }

  .fc2-switch {
    flex: 0;
  }
}

.fc2-collapse-body {
  max-height: 0;
  padding-top: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .4s;

  p {
    font-size: $fc2-fs-12;
  }
}

.fc2-collapse-icon {
  display: inline-block;
  margin-right: $fc2-spacer-10;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 5px 0 5px;
  border-color: $fc2-gray-100 transparent transparent transparent;
}
